import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class OktaGroupGuard {
    constructor(
        private readonly notificationService: NotificationService,
        private readonly translate: TranslateService,
        private readonly authService: AuthService,
    ) { }


    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.isGroupMembor(route.data['expectedGroup'] || []).pipe(
            tap(p => p || this.notificationService.errorMsg(this.translate.instant('route.noPermission')))
        );
    }
}
